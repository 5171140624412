define("discourse/plugins/discourse-page-visits/discourse/api-initializers/page-visits", ["exports", "discourse/lib/ajax", "discourse/lib/debounce", "discourse/lib/plugin-api"], function (_exports, _ajax, _debounce, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let pageVisitData = {};
  let pageEnter;
  let pageExit;
  let visitTime;
  let viewedPostIds = [];
  let screenTrack;
  let postStream;
  var _default = _exports.default = {
    name: "page-visits",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.24.0", api => {
        screenTrack = api.container.lookup("service:screen-track");
        const currentUser = api.getCurrentUser();
        const topicController = api.container.lookup("controller:topic");

        // capture when user leaves the page
        document.addEventListener("visibilitychange", () => {
          if (document.visibilityState === "hidden") {
            if (Object.keys(pageVisitData).length > 0) {
              setVisitTime();
              createPageVisitRecord(pageVisitData, viewedPostIds, visitTime);
              reset();
            }
          }
        });
        api.onPageChange(() => {
          if (Object.keys(pageVisitData).length > 0) {
            setVisitTime();
            createPageVisitRecord(pageVisitData, viewedPostIds, visitTime);
            reset();
          }
          const topicId = topicController.model?.id || null;
          postStream = topicController.model?.postStream;
          if (topicId && postStream) {
            window.addEventListener("scroll", scroll, {
              passive: true
            });
          }
          captureVisitData(currentUser?.id, topicId);
        });
      });
    }
  };
  function scroll() {
    (0, _debounce.default)(this, captureOnScreenPosts, 100);
  }
  function captureOnScreenPosts() {
    screenTrack._readPosts.forEach(index => {
      // screenTrack index is 1-based
      const postId = postStream.stream[index - 1];
      if (postId && !viewedPostIds.includes(postId)) {
        viewedPostIds.push(postId);
      }
    });
  }
  async function createPageVisitRecord(data, postIds, time) {
    await (0, _ajax.ajax)("/page-visits.json", {
      type: "POST",
      data: {
        user_id: data.userId,
        full_url: data.fullUrl,
        topic_id: data.topicId,
        post_ids: postIds,
        visit_time: time
      }
    });
  }
  function captureVisitData(userId, topicId) {
    const data = {
      userId: userId || null,
      fullUrl: window.location.href,
      topicId
    };
    pageVisitData = data;
  }
  function reset() {
    window.removeEventListener("scroll", scroll);
    viewedPostIds = [];
    pageEnter = new Date();
    pageExit = null;
    visitTime = null;
    pageVisitData = {};
  }
  function setVisitTime() {
    pageExit = new Date();
    visitTime = pageExit - pageEnter;
  }
});